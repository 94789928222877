<template>
  <div
    :id="elementId"
    :class="{ reduced: reduced, fullwidth: fullWidth }"
  ></div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, h, render as vueRender } from 'vue'
import algoliasearch from 'algoliasearch/lite'
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js'
import { useI18n } from 'vue-i18n'
import {
  getCitiesIndexName,
  getCategoriesIndexName,
  getFederatedSearchIndexName,
} from '~/utils/algoliaHelpers'
import type { EventDescription } from '~/composables/types/event'
import { eventGetters } from '~/utils/eventGetters'
import { useAlgoliaSearchInsights } from '~/composables/algolia/useAlgoliaSearchInsights'
import {
  useRouter,
  useRoute,
  useRuntimeConfig,
  useLocalePath,
  navigateTo,
} from '#imports'
import { useLastViewedEvents } from '~/composables/useLastViewedEvents'

const props = defineProps({
  elementId: { type: String, required: true },
  reduced: { type: Boolean, default: false },
  fullWidth: { type: Boolean, default: false },
})

const { t, locale } = useI18n()
const { isMobile } = useDevice()
const router = useRouter()
const $localePath = useLocalePath()
const localePath = $localePath
const config = useRuntimeConfig()
const searchClient = algoliasearch(
  config.public.algoliaAppId,
  config.public.algoliaApiClientKey
)
const { client: algoliaInsightsClient } = useAlgoliaSearchInsights()
const algoliaAutocompleteInstance = ref<any>(null)
const { lastViewedEvents } = useLastViewedEvents(true)

// helpers
const getProductPath = (item: any) => {
  if (item?.supplier === 'konfetti Gutscheine') {
    return `/giftcard/${item.permalink}`
  }
  if (eventGetters.isBoxEvent(item)) {
    return `/k/${item.permalink}`
  }
  return `/e/${item.permalink}`
}

const mountUrlParamsFromItem = (item: any) => {
  let geoloc = ''
  if (item?._geoloc) {
    geoloc = `geoloc=${item._geoloc.lat},${item._geoloc.lng}`
  }
  const city = 'city=' + encodeURIComponent(item.slug)
  return [geoloc, city].filter(Boolean).join('&')
}

const areThereEventResults = (eventResults: any) =>
  eventResults?.children?.[1]?.props?.className !== 'aa-SourceNoResults'

// autocomplete initialize on mounted
onMounted(() => {
  const el = document.getElementById(props.elementId)
  if (!el || el.childNodes.length > 0) return

  algoliaAutocompleteInstance.value = autocomplete({
    container: `#${props.elementId}`,
    defaultActiveItemId: 0,
    openOnFocus: true,
    insights: { insightsClient: algoliaInsightsClient },
    translations: {
      detachedCancelButtonText: 'cancel',
    },
    renderer: { createElement },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render({ elements, render, state, setIsOpen }, root) {
      const {
        eventCategories = null,
        events = null,
        localities = null,
      } = elements || {}

      const areThereCityOrCategoryResults =
        Boolean(localities) || Boolean(eventCategories)
      const shouldShowAllResultsButton =
        areThereCityOrCategoryResults || areThereEventResults(events)
      const shouldShowLastViewedEvents =
        (lastViewedEvents.value?.length ?? 0) > 0 && !state?.query

      const panelLayout = h(
        'div',
        { class: 'aa-PanelLayout aa-Panel--scrollable' },
        [
          h('div', { class: 'aa-PanelSections' }, [
            // Left column: cities and categories
            h('div', { class: 'aa-PanelSection--left' }, [
              h('div', null, localities),
              h('div', { class: 'aa-CategorySection' }, eventCategories),
            ]),
            // Right column: results of events
            h(
              'div',
              {
                class:
                  'aa-PanelSection--right ' +
                  (areThereCityOrCategoryResults
                    ? 'one-column'
                    : 'two-columns') +
                  (shouldShowLastViewedEvents ? ' hidden' : ''),
              },
              [
                events,
                h(
                  'div',
                  {
                    class:
                      'flex ' + (shouldShowAllResultsButton ? '' : 'hidden'),
                  },
                  [
                    h(
                      'a',
                      {
                        href: localePath(
                          `/search/${state?.query ? '?searchquery=' + state?.query : ''}`
                        ),
                        class:
                          'block mt-8 mb-4 ml-2 px-4 py-2 rounded-lg mx-auto text-white bg-primary cursor-pointer',
                      },
                      t('general.showAllResults')
                    ),
                  ]
                ),
              ]
            ),
            // Right column: recents views
            h(
              'div',
              {
                class:
                  'aa-PanelSection--right ml-4 ' +
                  (!shouldShowLastViewedEvents ? ' hidden' : ''),
              },
              [
                h('div', { class: 'flex my-4' }, [
                  h(
                    'span',
                    { class: 'aa-SourceHeaderTitle shrink-0' },
                    t('home.lastViewedEvents')
                  ),
                  h('div', { class: 'aa-SourceHeaderLine !relative w-full' }),
                ]),
                ...(lastViewedEvents.value
                  ?.slice(0, 5)
                  ?.map((event: EventDescription) =>
                    h(
                      'div',
                      {
                        class: 'aa-ItemContent',
                        onClick: () => {
                          setIsOpen(false)
                          router.push(localePath(`/e/${event.permalink}`))
                        },
                      },
                      [
                        h('img', {
                          class: 'w-20 h-16 mb-2 object-cover mr-2 rounded-md',
                          src: event?.images?.[0]?.thumb,
                          alt: event?.label,
                        }),
                        h(
                          'span',
                          { class: 'aa-ItemContentTitle' },
                          event?.title
                        ),
                      ]
                    )
                  ) || []),
              ]
            ),
          ]),
        ]
      )
      vueRender(panelLayout, root)
      if (props.fullWidth) {
        const resultsPanel = document.getElementsByClassName('aa-Panel')
        if (resultsPanel && resultsPanel[0]) {
          resultsPanel[0].classList.add('aa-Panel-fullwidth')
        }
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getSources({ setIsOpen }) {
      return [
        {
          sourceId: 'localities',
          getItemInputValue: ({ item }: any) => item.query,
          getItems({ query }: any) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: getCitiesIndexName(),
                  query,
                  params: {
                    hitsPerPage: isMobile ? 3 : 6,
                    attributesToSnippet: ['name:24'],
                    snippetEllipsisText: '…',
                    optionalWords: query,
                    aroundLatLngViaIP: true,
                    aroundRadius: 'all',
                    aroundPrecision: [
                      { from: 0, value: 15000 },
                      { from: 30000, value: 30000 },
                      { from: 150000, value: 100000 },
                    ],
                  },
                },
              ],
            })
          },
          getItemUrl({ item }: any) {
            return localePath(`/search/?${mountUrlParamsFromItem(item)}`)
          },
          templates: {
            header() {
              return h('div', { class: 'mr-8' }, [
                h(
                  'span',
                  { class: 'aa-SourceHeaderTitle' },
                  t('home.header.search.selectCity')
                ),
                h('div', { class: 'aa-SourceHeaderLine' }),
              ])
            },
            item({ item, components }: any) {
              return h('div', { class: 'aa-ItemContent' }, [
                h('span', { class: 'aa-ItemContentTitle' }, item.name),
                components.Highlight({ hit: item, attribute: 'name' }),
              ])
            },
          },
          onSelect({ item, setIsOpen }: any) {
            setIsOpen(false)
            router.push(localePath(`/search/?${mountUrlParamsFromItem(item)}`))
          },
        },
        {
          sourceId: 'eventCategories',
          getItemInputValue: ({ item }: any) => item.query,
          getItems({ query }: any) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: getCategoriesIndexName(locale.value),
                  query,
                  params: {
                    hitsPerPage: 3,
                    attributesToSnippet: ['name:24'],
                    snippetEllipsisText: '…',
                    optionalWords: query,
                  },
                },
              ],
            })
          },
          getItemUrl({ item }: any) {
            return localePath(`/c/${item?.slug}`)
          },
          templates: {
            header() {
              return h('div', null, [
                h(
                  'span',
                  { class: 'aa-SourceHeaderTitle' },
                  t('general.categories')
                ),
                h('div', { class: 'aa-SourceHeaderLine' }),
              ])
            },
            item({ item, components }: any) {
              return h('div', { class: 'aa-ItemContent' }, [
                h('span', { class: 'aa-ItemContentTitle' }, item.name),
                components.Highlight({ hit: item, attribute: 'name' }),
              ])
            },
          },
          onSelect({ item, setIsOpen }: any) {
            setIsOpen(false)
            router.push(localePath(`/c/${item.slug}`))
          },
        },
        {
          sourceId: 'events',
          getItemInputValue: ({ item }: any) => item.query,
          getItems({ query }: any) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: getFederatedSearchIndexName(locale.value),
                  query,
                  params: {
                    hitsPerPage: 8,
                    attributesToSnippet: ['name:24'],
                    snippetEllipsisText: '…',
                    aroundLatLngViaIP: true,
                    aroundRadius: 'all',
                    aroundPrecision: [
                      { from: 0, value: 15000 },
                      { from: 30000, value: 30000 },
                      { from: 150000, value: 100000 },
                    ],
                  },
                },
              ],
            })
          },
          getItemUrl({ item }: any) {
            return item.url + ' '
          },
          templates: {
            header() {
              return h('div', null, [
                h(
                  'span',
                  { class: 'aa-SourceHeaderTitle' },
                  t('general.events')
                ),
                h('div', { class: 'aa-SourceHeaderLine' }),
              ])
            },
            item({ item }: any) {
              return h('div', { class: 'aa-ItemContent' }, [
                h('img', {
                  class: 'w-20 h-16 object-cover mr-2 rounded-md',
                  src: item.main_image?.thumb,
                  alt: item.main_image?.alt,
                }),
                h('span', { class: 'aa-ItemContentTitle' }, item.title),
              ])
            },
            noResults() {
              return t('general.select.noResultsFound')
            },
          },
          onSelect({ item, setIsOpen }: any) {
            setIsOpen(false)
            router.push(localePath(getProductPath(item)))
          },
        },
      ]
    },
    placeholder: t('search.algoliaAutocomplete.placeholder'),
    onSubmit({ state, setIsOpen }: any) {
      setIsOpen(false)
      navigateTo({
        path: localePath('/search/'),
        query: { searchquery: state?.query },
      })
      state.query = ''
    },
    navigator: {
      navigate({ state }: any) {
        navigateTo({
          path: localePath('/search/'),
          query: { searchquery: state?.query },
        })
        state.query = ''
      },
    },
  })
})

// close autocomplete on route change
const route = useRoute()
watch(
  () => route.fullPath,
  () => {
    setTimeout(() => {
      algoliaAutocompleteInstance.value?.setIsOpen(false)
    }, 0)
  }
)
</script>

<style lang="postcss">
@import '~/assets/css/federated-search.css';
</style>
